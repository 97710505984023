import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ReactDOM from 'react-dom';
import App from './App';
import Paragraph from './Paragraph';

ReactDOM.render(
  <React.StrictMode>
		<Router>
			<Routes>
				<Route path='/read/:doc/:paragraph' element={<Paragraph/>} />
				<Route path='/read/:doc/:book/:paragraph' element={<Paragraph/>} />
				<Route path='/' element={<App/>} />
			</Routes>
		</Router>
  </React.StrictMode>,
  document.getElementById('root')
);
