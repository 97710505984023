import './Main.css';

function App() {
  return (
		<div>
        <div className='texts'>
					<h1>Interactive Latin Texts</h1>
					<a href="https://www.patreon.com/bePatron?u=64941077"
             data-patreon-widget-type="become-patron-button">
               Become a Patron!
          </a>
					<p>
						Welcome! Latin is a heavily <a href='https://en.wikipedia.org/wiki/Inflection'>inflected</a> language,
						which can make it seem overwhelming to beginners. To help those wanting to learn this beautiful and
						influential language, I&#39;ve put together these interactive Latin texts. Click on any word in the texts
					  below to view all possible meanings of that word as it is used in the sentence, including possible inflections,
						parts of speech, and definitions.
					</p>
					<p>
						To get the most out of this site, you&#39;ll want to familiarize yourself with at least the basics of
						Latin grammar, especially with all of the possible noun cases and verb tenses. Wikipedia has a surprisingly
 						good overview: <a href='https://en.wikipedia.org/wiki/Latin_grammar'>Latin grammar.</a>
					</p>
					<h2>Caesar - The Gallic Wars</h2>
						<h3>
							<a href='/read/caesars_gallic_wars/book_one/1_of_54'>
								Book&nbsp;I 
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_two/1_of_35'>
								Book&nbsp;II
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_three/1_of_29'>
								Book&nbsp;III
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_four/1_of_38'>
								Book&nbsp;IV
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_five/1_of_58'>
								Book&nbsp;V
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_six/1_of_44'>
								Book&nbsp;VI
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_seven/1_of_90'>
								Book&nbsp;VII
							</a>
							<span> </span>
							<a href='/read/caesars_gallic_wars/book_eight/1_of_56'>
								Book&nbsp;VIII
							</a>
					</h3>
					<h2>Tacitus - Histories</h2>
						<h3>
							<a href='/read/tacitus_histories/book_one/1_of_88'>
								Book&nbsp;I 
							</a>
							<span> </span>
							<a href='/read/tacitus_histories/book_two/1_of_101'>
								Book&nbsp;II
							</a>
							<span> </span>
							<a href='/read/tacitus_histories/book_three/1_of_86'>
								Book&nbsp;III
							</a>
							<span> </span>
							<a href='/read/tacitus_histories/book_four/1_of_86'>
								Book&nbsp;IV
							</a>
							<span> </span>
							<a href='/read/tacitus_histories/book_five/1_of_26'>
								Book&nbsp;V
							</a>
					</h3>
					<h2>Livy - The History of Rome Books 1-10</h2>
						<h3>
							<a href='/read/livy_the_history_of_rome/book_I/1_of_89'>
								Book&nbsp;I 
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_II/1_of_86'>
								Book&nbsp;II
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_III/1_of_101'>
								Book&nbsp;III
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_IV/1_of_95'>
								Book&nbsp;IV
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_V/1_of_93'>
								Book&nbsp;V
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_VI/1_of_86'>
								Book&nbsp;VI
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_VII/1_of_91'>
								Book&nbsp;VII
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_VIII/1_of_88'>
								Book&nbsp;VIII
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_IX/1_of_105'>
								Book&nbsp;IX
							</a>
							<span> </span>
							<a href='/read/livy_the_history_of_rome/book_X/1_of_106'>
								Book&nbsp;X
							</a>
							<span> </span>
						</h3>
					<h2>Sallust</h2>
						<h3>
							<a href='/read/sallust_the_catiline_conspiracy/1_of_97'>
								The Catiline Conspiracy
							</a>
						</h3>
				</div>
		</div>
  );
}

export default App;
