import React from 'react';
import Header from './Header.js';
import './Main.css';


const BOOKS = {
	'caesars_gallic_wars/book_one': "Caesar - The Gallic Wars: Book I",
	'caesars_gallic_wars/book_two': "Caesar - The Gallic Wars: Book II",
	'caesars_gallic_wars/book_three': "Caesar - The Gallic Wars: Book III",
	'caesars_gallic_wars/book_four': "Caesar - The Gallic Wars: Book IV",
	'caesars_gallic_wars/book_five': "Caesar - The Gallic Wars: Book V",
	'caesars_gallic_wars/book_six': "Caesar - The Gallic Wars: Book VI",
	'caesars_gallic_wars/book_seven': "Caesar - The Gallic Wars: Book VII",
	'caesars_gallic_wars/book_eight': "Caesar - The Gallic Wars: Book VIII",
	'tacitus_histories/book_one': "Tacitus - Histories: Book I",
	'tacitus_histories/book_two': "Tacitus - Histories: Book II",
	'tacitus_histories/book_three': "Tacitus - Histories: Book III",
	'tacitus_histories/book_four': "Tacitus - Histories: Book IV",
	'tacitus_histories/book_five': "Tacitus - Histories: Book V",
	'livy_the_history_of_rome/book_I': "Livy - The History of Rome: Book I",
	'livy_the_history_of_rome/book_II': "Livy - The History of Rome: Book II",
	'livy_the_history_of_rome/book_III': "Livy - The History of Rome: Book III",
	'livy_the_history_of_rome/book_IV': "Livy - The History of Rome: Book IV",
	'livy_the_history_of_rome/book_V': "Livy - The History of Rome: Book V",
	'livy_the_history_of_rome/book_VI': "Livy - The History of Rome: Book VI",
	'livy_the_history_of_rome/book_VII': "Livy - The History of Rome: Book VII",
	'livy_the_history_of_rome/book_VIII': "Livy - The History of Rome: Book VIII",
	'livy_the_history_of_rome/book_IX': "Livy - The History of Rome: Book IX",
	'livy_the_history_of_rome/book_X': "Livy - The History of Rome: Book X",
	'sallust_the_catiline_conspiracy': "Sallust - The Catiline Conspiracy",
}

class Paragraph extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			title: '',
			paragraph: [],
			paragraph_num: 0,
			num_paragraphs: 0,
			selected: -1}

		this.tokenClicked = this.tokenClicked.bind(this);
		this.dismissAnnotation = this.dismissAnnotation.bind(this);
	}

	componentDidMount() {
		// Paths like work/book/1_of_N.json or work/1_of_N.json
		const path_components = window.location.pathname.split('/').slice(2);

		// Use a simple dict to map from path to book name.
		// length 3 if the work has multiple books.
		var book_path;
		if (path_components.length === 3) {
			book_path = path_components[0] + '/' + path_components[1];
		} else {
			book_path = path_components[0];
		}

		const book_title = BOOKS[book_path];
		const json_path = process.env.PUBLIC_URL + '/' + path_components.join('_') + '.json';
		console.log(json_path);

		document.title = book_title;

		// Named things like 1_of_54
		const file_number = path_components[path_components.length - 1];
		const file_number_parts = file_number.split('_');
		const paragraph_num = parseInt(file_number_parts[0]);
		const num_paragraphs = parseInt(file_number_parts[2]);
		this.setState({
			title: book_title,
			book_path: book_path,
			paragraph_num: paragraph_num,
			num_paragraphs: num_paragraphs});
			
		// Load the JSON data.
		fetch(json_path)
        .then(response => response.json())
        .then(data => this.setState({ paragraph: data}));

		// Allow user to dismiss pop-up by clicking anywhere besides a word.
		document.body.addEventListener('click', this.dismissAnnotation);
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.dismissAnnotation);
	}

	tokenClicked(e, num) {
		// Prevent body event from firing.
		e.stopPropagation();
		this.setState({ selected: num, clientY: e.clientY });
	}

	dismissAnnotation() {
		this.setState({ selected: -1, clientY: -1 });
	}

  render() {
		
		var paragraph_links = [];
		const book_path = '/read/' + this.state.book_path;
		const out_of_path = '_of_' + this.state.num_paragraphs;

		// Make paragraph links.
		var link;
		for (var i = 0; i < this.state.num_paragraphs; i++) {
			if ((i + 1) === this.state.paragraph_num) {
				link = (<span key={i} className='paragraphLink'> {i + 1} </span>);
			} else {
				link = (<span key={i} className='paragraphLink'> <a href={book_path + '/' + (i + 1) + out_of_path}>{i + 1}</a> </span>);
			}
			paragraph_links.push(link);
		}

		var selected_token = null;
		if (this.state.selected > -1) {
			selected_token = this.state.paragraph[this.state.selected]
		}
		return (
			<div>
				<Header />
				<h1 className='title'>{this.state.title}</h1>
				<div className='paragraph'>
					<p>
						<span className='paragraphNum'>[{this.state.paragraph_num}]</span> &nbsp;
						{this.state.paragraph.map((token, i) => {
							var tokenClass;
							if (i === this.state.selected) {
								tokenClass = 'token tokenSelected';
							} else {
								tokenClass = 'token';
							}
							return (<span className={tokenClass} onClick={(e) => this.tokenClicked(e, i)} key={i}>
												{token['display'] + ' '}
											</span>);
						 })}
					</p>
					<div className='paragraphLinks'>
						<div className='prevNext'>
							{this.state.paragraph_num > 1 &&
							 <a className='paragraphLink' href={book_path + '/' + (this.state.paragraph_num - 1) + out_of_path}>&lt; Prev</a>}
							{this.state.paragraph_num < this.state.num_paragraphs &&
							 <a className='paragraphLink' href={book_path + '/' + (this.state.paragraph_num + 1) + out_of_path}>Next &gt;</a>}
						</div>
						{paragraph_links}
					</div>
				</div>
				{this.state.selected > -1 && <Annotation clientY={this.state.clientY} token={selected_token} />}
			</div>
		)
  }
}


class Annotation extends React.Component {

	render() {
		// Position annotation under clicked word.
		const w = window.innerWidth;
		const h = window.innerHeight;


		// Choose padding based on window size.
		var leftPad;
		var widthSize;
		if (w < 600) {
			leftPad = 0.1 * w;
			widthSize = 0.8 * w;
		} else if (w < 1200) {
			leftPad = 0.15 * w;
			widthSize = 0.7 * w;
		} else {
			leftPad = 0.2 * w;
			widthSize = 0.6 * w;
		}

		const divPos = {
			position: 'absolute',
			top: (this.props.clientY + window.scrollY + Math.floor(0.1 * h)) + 'px',
			left: Math.floor(leftPad) + 'px',
			width: Math.floor(widthSize) + 'px'
		};

		// Remove punctuation from the token.
		var token = this.props.token['display']
										.replace(',', '')
										.replace(';', '')
										.replace('?', '')
										.replace('!', '')
										.replace('.', '')
										.replace(':', '')
										.replace('-', '');

		// Handle -que, -ne, -ve suffixes.
		var suffix = this.props.token['suffix'];
		if (suffix) {
			token = token.slice(0, token.length - suffix.length);
		}
		
		return (
			<div style={divPos} className='annotation'>
				<h1>{token} {suffix && <span>+ {suffix}</span>}</h1>
				{this.props.token['options'].map((lemma, i) => {
					return (<Lemma key={i} display={token}
																 name={lemma['lemma']}
																 forms={lemma['forms']}
																 meanings={lemma['meanings']} />);
				})}
			</div>
	);
}
}


class Lemma extends React.Component {

render() {
	const lemma = this.props.name;
	var inflected = this.props.display;
	// If the lemma is lower-cased, then display the word as lower-cased.
	if (inflected[0] !== inflected[0].toLowerCase() &&
			lemma[0] === lemma[0].toLowerCase()) {
		inflected = inflected[0].toLowerCase() + inflected.substring(1);
	}
		
	return (
		<div>
			<h3>{lemma}</h3>
			<div>
				<ul>
						{this.props.forms.map((f, i) => {
							if (f === 'base') {
								return (<span key={i}></span>);
							} else {
								return (<li className='inflectedForms' key={i}>
													<span className='displayForm'>{inflected}: </span>
													{f}
												</li>);
							}
						})}
				</ul>
			{this.props.meanings.map((meaning, i) => {
				return (<Meaning key={i} type={meaning['type']}
												 header={meaning['header']}
												 definitions={meaning['definitions']} />);
			})}
			</div>
		</div>
		);
	}
}


class Meaning extends React.Component {
	
	render() {
		return (
			<div className='meaning'>
				<h4>{this.props.type}: {this.props.header}</h4>
				<ol>
					{this.props.definitions.map((d, i) => {
						return (<li key={i}>{d}</li>);
					})}
				</ol>
			</div>
		);
	}

}


export default Paragraph;
