import React from 'react';
import './Main.css';

class Header extends React.Component {
	
	render() {
		return (
			<header>
				<a href='/' className='headerText'>
					Home
				</a>
				<div className='patreon'>
					<a href="https://www.patreon.com/bePatron?u=64941077"
						 data-patreon-widget-type="become-patron-button">
								 Become a Patron!
					</a>
				</div>
			</header>
		);
	}
}

export default Header;


